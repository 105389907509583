function forms() {
    var fileReceiver = $('.file-receiver'),
        fileList = [];

    function addFile(fileLink) {
        var r = new FileReader();

        r.onload = (function (fileLink) {
            return function (e) {
                var contents = e.target.result;

                fileList.push({
                    filename:fileLink.name,
                    file: new File([contents], fileLink.name, fileLink)
                });

            };
        })(fileLink);

        r.readAsBinaryString(fileLink);
    }

    function drawFilenameList($list) {
        var markup = '<span>'+'Прикреплен файл: ' + $list.join('</span><span>') + '</span>';

        $('.file-help-block').html(markup);
    }

    function updateTextarea($originalTarget) {
        var target = $($originalTarget).closest('form').find('textarea[required]');
        target.removeAttr('required');
        target.trigger('change');
    }

    $('input[type="file"]').change(function () {
        if (this.files.length) {
            addFile(this.files[0]);
        }
        // updateTextarea(this);
         drawFilenameList([this.files[0].name]);
    });

    fileReceiver
        .on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
            e.preventDefault();
            e.stopPropagation();
        }).on('dragover dragenter', function() {

            fileReceiver.addClass('is-dragover');
        }).on('dragleave dragend drop', function() {

            fileReceiver.removeClass('is-dragover');
        }).on('drop', function(e) {

            var filenames = [];

            $.each(e.originalEvent.dataTransfer.files, function(i, file) {
                filenames.push(file.name);
                addFile(file);
            });

            // updateTextarea(e.target);
            drawFilenameList(filenames);
        });

    return fileList;
}

function initForms() {

    function resetForms($form) {
        $form.reset();
    }

    $('.button_to_order').click(function ($event) {
        $event.preventDefault();

        setTimeout(function () {
            $('#getOfferModal').modal('show');
        }, 200);
    });

    $('form[data-toggle="validator"]').validator().on('submit', function (e) {
        if (e.isDefaultPrevented()) {
            console.log('invalid');
            console.log(e.target);
        } else {
            e.preventDefault();

            if ($(this).closest('.modal').length) {
                $('#' + $(this).closest('.modal').attr('id')).modal('hide');
            }

            var data = new FormData();

            if ($(e.target).find('textarea').length && document.AMfileInputsWrapper) {
                document.AMfileInputsWrapper.forEach(function (item, index) {
                    data.append('file-' + index, item.file);
                });
            }

            jQuery.each($(e.target).find('input[name]:not([type="file"])'), function(i, item) {
                data.append($(item).attr('name'), $(item).val());
            });

            jQuery.each($(e.target).find('textarea[name]'), function(i, item) {
                data.append($(item).attr('name'), $(item).val());
            });

            $.ajax({
                url: $(e.target).attr('action'),
                data: data,
                cache: false,
                contentType: false,
                processData: false,
                method: 'POST',
                type: 'POST', // For jQuery < 1.9
                success: function(){

                    $('#thanksModal').modal('show');
                    resetForms(e.target)

                    setTimeout(function() {
                        $('#thanksModal').modal('hide');
                    }, 5300);
                }
            });
        }
    });

}