if($(".articles .grid").length && $(window).width() > 991){
    $(' .articles .grid').masonry({
        // options...
        itemSelector: '.item',
        columnWidth: ".grid_sizer",
        gutter: ".gutter",
        percentPosition: true
    });
}

if($(".blog .grid").length && $(window).width() > 991){
    $(' .blog .grid').masonry({
        // options...
        itemSelector: '.item',
        columnWidth: ".grid_sizer",
        gutter: ".gutter",
        percentPosition: true
    });
}

if($(".grid_container .grid").length && $(window).width() > 991){
    setTimeout(function(){
        $('.grid_container .grid').masonry({
            // options...
            itemSelector: '.item_good',
            columnWidth: ".grid_sizer2",
            gutter: ".gutter2",
            percentPosition: true
        });
    }, 1000);
}

if($(".container_for_promo").length && $(window).width() > 991){
    $(' .container_for_promo').masonry({
        // options...
        itemSelector: '.sale',
        columnWidth: ".container_for_promo .grid_sizer",
        gutter: ".gutter_promo",
        percentPosition: true
    });
}

// if($(".container_for_catalog_items").length/* && $(window).width() > 991*/){
//     setTimeout(function(){
//         $('.container_for_catalog_items').masonry({
//             // options...
//             itemSelector: '.item_catalog',
//             columnWidth: ".grid_sizer_catalog",
//             gutter: ".gutter_catalog",
//             percentPosition: true
//         });
//     }, 1000);
// }


$(document).on("click", 'button.increase', function(e){
    e.preventDefault();
    var val = parseInt($(this).prev(".item-quantity").val());
    val++;
    $(this).prev(".item-quantity").val(val);
});

// $('input.item-quantity').keyup(function() {
//     var val = parseInt($(this).val());
//     $(this).val(val);
// });
$('input.item-quantity').on("blur", function() {
    let amount = parseInt($(this).val());
    if(isNaN(amount)){
        $(this).val(1);
    } else{
        $(this).val(amount);
    }
});

$(document).on("click", 'button.decrease', function(e){
    e.preventDefault();
    var val = parseInt($(this).next(".item-quantity").val());
    var id;
    console.log("val = "+val);
    console.log(val>0);
    if (val > 1) {
        console.log("val is greater than 1");
        val--;
        $(this).next(".item-quantity").val(val);
    }
    else {
        console.log("val is less than 1");
    }
});

$(document).on("click", 'button.to_cart', function(e){
    e.preventDefault();
    $(".button_container").removeClass("amount_mode");
    $(this).closest(".button_container").addClass("amount_mode");
});

$(document).on("click", ".burger_item", function(e){
    if($(e.target).hasClass("close")){
        $("header.black .burger_item").removeClass("open");
    }
    else{
        $("header.black .burger_item").addClass("open");
    }
});

$(document).on("click", ".burger", function(e){
    if($(e.target).hasClass("close")){
        $(".navigation .burger").removeClass("open");
    }
    else{
        $(this).addClass("open");
    }
});

$(document).ready(function () {

    initForms();

    // File input handler
    document.AMfileInputsWrapper = forms();

    $(".phone-masked-input").mask("+79999999999", {placeholder:" "});
});

if ($(".map_container").length) {
    if (typeof ymaps !== 'undefined') {
        ymaps.ready(init);

        function init() {
            var myMap = new ymaps.Map('map', {
                center: [55.736143, 37.612840],
                zoom: 9,
                controls: []
            });

            $('.delivery .select_wrapper select').on('change', function (e) {
                //e.preventDefault();
                var city = $(this).find(":selected").data("map");
                console.log(city);

                myMap.panTo(destinations[city], {
                    flying: 1
                });
            });
        }
    }
}

if($(".container_for_slider").length){
    $(".container_for_slider").slick({
        dots: false,
        arrows: true,
        draggable: false,
        infinite: true,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 1,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    arrows: false,
                    draggable: true,
                    slidesToShow: 4
                }
            },
            {

                breakpoint: 570,
                settings: {
                    arrows: false,
                    draggable: true,
                    slidesToShow: 3,
                }
            },
            {

                breakpoint: 400,
                settings: {
                    arrows: false,
                    draggable: true,
                    slidesToShow: 2,
                }
            }
        ]
    });
}

$(document).on("click", ".cabinet .order-item .more", function(e){
    e.preventDefault();
    if($(this).hasClass("opened")){
        $(this).prev(".order-list").find(".item.hidden").addClass("hide");
        $(this).html($(this).attr("data-text")).removeClass("opened");
    }
    else{
        $(this).prev(".order-list").find(".item.hidden").removeClass("hide");
        $(this).html($(this).attr("data-swap-text")).addClass("opened");
    }
});

$('.cart table a.delete').hover(function(e){
    $(this).closest("tr").addClass("hover");
}, function () {
    $(this).closest("tr").removeClass("hover");
});

$(document).on("click", ".cart table a.delete", function(e){
    e.preventDefault();
    $(this).closest("tr").addClass("deleted");
});

// $(document).on("click", ".cart .slider a.hide", function(e){
//     e.preventDefault();
//     $(this).closest(".slider").hide().next(".enter").hide();
// });


if($("section.sticky").length && $(window).width() > 991){
    $(window).on("scroll", function () {
        if($("html")[0].scrollTop > 600){
            console.log($("html")[0].scrollTop);
            $("section.sticky").addClass("active");
        } else{
            $("section.sticky").removeClass("active");
        }
    });
}

$(document).on('click', function (e) {
    if ($(e.target).closest("section.modal").length && !$(e.target).closest("section.modal .content").length) {
        $("section.modal").removeClass("visible");
        //$("body").css("overflow", "inherit");
    }
    e.stopPropagation();
});

$(document).on("click", "section.modal .close", function (e) {
    e.preventDefault();
    $(this).closest("section.modal").removeClass("visible");
    //$("body").css("overflow", "inherit");
});

$(document).on("click", ".data-trigger", function (e) {
    e.preventDefault();
    e.stopPropagation();
    $(".modal.visible").removeClass("visible");
    $("#" + $(this).attr("data-trigger")).addClass("visible");
    //$("body").css("overflow", "hidden");
});


$(document).on("hover", "nav a", function (e) {

    if($(window).width() >= 991) {
        e.preventDefault();//
        e.stopPropagation();
        $(this).addClass("active").next(".submenu").addClass("visible");
    }
});

if($(window).width() >= 991) {
    $(document).on("click", "nav a", function (e) {
        if(!$(this).hasClass("end")){
            e.preventDefault();
            e.stopPropagation();
            console.log($(this).siblings("a"));
            $(this).siblings("a").removeClass("active").next(".submenu").removeClass("visible");
            $(this).addClass("active").next(".submenu").addClass("visible");
        }
    });

}

$(document).on('click', function (e) {
    if (!$(e.target).closest("nav a").length) {
        $(".submenu").removeClass("visible");
        $("nav a").removeClass("active");
    }
    e.stopPropagation();
});



